import { variables } from './variables'
import cssVars from 'css-vars-ponyfill'
export default function changeTheme(theme) {
  // TODO 后面看下这个css var插件为什么不行了
  // document.documentElement.setAttribute('data-theme', theme)
  // localStorage.setItem('data-theme', theme)
  // cssVars({
  //   watch: true,
  //   variables: variables[theme],
  //   onlyLegacy: false
  // })
}
