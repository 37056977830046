import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "data" }
const _hoisted_2 = { class: "total" }
const _hoisted_3 = { class: "query-wrap flex-row-all-center" }
const _hoisted_4 = { style: {"margin-right":"3px"} }
const _hoisted_5 = { class: "suplus" }
const _hoisted_6 = { class: "flex-row-all-center" }
const _hoisted_7 = { class: "expense" }
const _hoisted_8 = { class: "income" }
const _hoisted_9 = { class: "structure" }
const _hoisted_10 = { class: "head" }
const _hoisted_11 = { class: "tab" }
const _hoisted_12 = { class: "content" }
const _hoisted_13 = { class: "left" }
const _hoisted_14 = { class: "type" }
const _hoisted_15 = { class: "progress" }
const _hoisted_16 = { class: "right" }
const _hoisted_17 = { class: "percent" }
const _hoisted_18 = { class: "money" }
const _hoisted_19 = { class: "proportion" }
const _hoisted_20 = { class: "head" }
const _hoisted_21 = { class: "tab" }
const _hoisted_22 = {
  key: 0,
  class: "chart-empty-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_icon = _resolveComponent("van-icon")!
  const _component_icon = _resolveComponent("icon")!
  const _component_van_empty = _resolveComponent("van-empty")!
  const _component_van_progress = _resolveComponent("van-progress")!
  const _component_ledger_type_popup = _resolveComponent("ledger-type-popup")!
  const _component_PopMonth = _resolveComponent("PopMonth")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "time",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.monthToggle && _ctx.monthToggle(...args)))
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.currentMonth), 1),
          _createVNode(_component_van_icon, { name: "notes-o" })
        ]),
        _createElementVNode("div", {
          class: "flex-row-all-center",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showLedgerType = true))
        }, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.ledger_type), 1),
          _createVNode(_component_icon, { "icon-class": "icon-arrow_down_fat" })
        ])
      ]),
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "title" }, "结余", -1)),
      _createElementVNode("div", _hoisted_5, "¥" + _toDisplayString($setup.suplus || 0), 1),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, "总支出" + _toDisplayString(_ctx.total_expense || 0), 1),
        _createElementVNode("div", _hoisted_8, "  共收入¥" + _toDisplayString(_ctx.total_income || 0), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _cache[9] || (_cache[9] = _createElementVNode("span", { class: "title" }, "收支构成", -1)),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("span", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeTotalType('expense'))),
            class: _normalizeClass(["expense", { active: _ctx.totalType == 'expense' }])
          }, " 支出 ", 2),
          (_ctx.ledger_type !== '投资账本')
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeTotalType('income'))),
                class: _normalizeClass(["income", { active: _ctx.totalType == 'income' }])
              }, " 收入 ", 2))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        (
            (_ctx.totalType == 'expense' &&
              (!_ctx.expense_data || !_ctx.expense_data.length)) ||
            (_ctx.totalType == 'income' && (!_ctx.income_data || !_ctx.income_data.length))
          )
          ? (_openBlock(), _createBlock(_component_van_empty, {
              key: 0,
              "image-size": "80",
              description: "暂无数据"
            }))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.totalType == 'expense' ? _ctx.expense_data : _ctx.income_data, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "item",
                key: item.bill_type
              }, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("span", {
                      class: _normalizeClass({
                    expense: _ctx.totalType == 'expense',
                    income: _ctx.totalType == 'income',
                  })
                    }, [
                      _createElementVNode("i", {
                        class: _normalizeClass(["iconfont", _ctx.notNull(item.bill_type) ? item.bill_type_icon : 0])
                      }, null, 2)
                    ], 2),
                    _createElementVNode("span", null, _toDisplayString(item.bill_type_name), 1)
                  ]),
                  _createElementVNode("div", _hoisted_15, _toDisplayString(Number(
                    (item.money /
                      Number(
                        _ctx.totalType == 'expense' ? _ctx.total_expense : _ctx.total_income
                      )) *
                      100
                  ).toFixed(2)) + "% ", 1)
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createVNode(_component_van_progress, {
                      percentage: 
                    Number(
                      (item.money /
                        Number(
                          _ctx.totalType == 'expense' ? _ctx.total_expense : _ctx.total_income
                        )) *
                        100
                    )
                  ,
                      "stroke-width": "6px",
                      "show-pivot": false,
                      "track-color": "#ffffff",
                      color: "#39be77"
                    }, null, 8, ["percentage"])
                  ]),
                  _createElementVNode("div", _hoisted_18, "¥" + _toDisplayString(Number(item.money).toFixed(2) || 0), 1)
                ])
              ]))
            }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, [
        _cache[10] || (_cache[10] = _createElementVNode("span", { class: "title" }, "收支构成", -1)),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("span", {
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changePieType('expense'))),
            class: _normalizeClass(["expense", { active: _ctx.pieType == 'expense' }])
          }, "支出", 2),
          (_ctx.ledger_type !== '投资账本')
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.changePieType('income'))),
                class: _normalizeClass(["income", { active: _ctx.pieType == 'income' }])
              }, " 收入 ", 2))
            : _createCommentVNode("", true)
        ])
      ]),
      (
          (_ctx.pieType == 'expense' && (!_ctx.expense_data || !_ctx.expense_data.length)) ||
          (_ctx.pieType == 'income' && (!_ctx.income_data || !_ctx.income_data.length))
        )
        ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
            _createVNode(_component_van_empty, {
              image: require('../assets/images/empty-chart.png'),
              "image-size": "80",
              description: "暂无数据"
            }, null, 8, ["image"])
          ]))
        : _createCommentVNode("", true),
      _cache[11] || (_cache[11] = _createElementVNode("canvas", { id: "proportion" }, null, -1))
    ]),
    _createVNode(_component_ledger_type_popup, {
      "show-ledger-type": _ctx.showLedgerType,
      "onUpdate:showLedgerType": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showLedgerType) = $event)),
      "ledger-type": _ctx.ledger_type,
      "onUpdate:ledgerType": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.ledger_type) = $event)),
      "exclude-ledger": ['cycle_ledger', 'toloan_ledger'],
      onOnSelectLedger: _ctx.selectLedger
    }, null, 8, ["show-ledger-type", "ledger-type", "onOnSelectLedger"]),
    _createVNode(_component_PopMonth, {
      ref: "popMonthRef",
      onSelectTime: _ctx.selectMonth
    }, null, 8, ["onSelectTime"])
  ]))
}