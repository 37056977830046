import {
  Tabbar,
  TabbarItem,
  Form,
  Field,
  Button,
  Picker,
  Icon,
  List,
  Cell,
  PullRefresh,
  CellGroup,
  Popup,
  DatePicker,
  NumberKeyboard,
  Dialog,
  NavBar,
  Rate,
  Collapse,
  CollapseItem,
  Tab,
  Tabs,
  Tag,
  Popover,
  Empty,
  Calendar,
  TimePicker,
} from 'vant';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import 'vant/es/notify/style';
import 'vant/es/image-preview/style';
import 'vant/lib/index.css';

let components = [
  Empty,
  Tab,
  Tabs,
  Tabbar,
  TabbarItem,
  Form,
  Field,
  Button,
  Picker,
  Icon,
  List,
  PullRefresh,
  Cell,
  CellGroup,
  Popup,
  DatePicker,
  NumberKeyboard,
  Dialog,
  NavBar,
  Rate,
  Collapse,
  CollapseItem,
  Tag,
  Popover,
  Calendar,
  TimePicker,
];

export default function registerVant(app: any) {
  components.map((v) => {
    app.use(v);
  });
}
