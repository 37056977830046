<template>
  <div class="data">
    <div class="total">
      <div class="query-wrap flex-row-all-center">
        <div class="time" @click="monthToggle">
          <span>{{ currentMonth }}</span>
          <van-icon name="notes-o" />
        </div>
        <div class="flex-row-all-center" @click="showLedgerType = true">
          <span style="margin-right: 3px">{{ ledger_type }}</span>
          <icon icon-class="icon-arrow_down_fat" />
        </div>
      </div>
      <div class="title">结余</div>
      <div class="suplus">¥{{ suplus || 0 }}</div>
      <div class="flex-row-all-center">
        <div class="expense">总支出{{ total_expense || 0 }}</div>
        <div class="income">&nbsp;&nbsp;共收入¥{{ total_income || 0 }}</div>
      </div>
    </div>
    <div class="structure">
      <div class="head">
        <span class="title">收支构成</span>
        <div class="tab">
          <span
            @click="changeTotalType('expense')"
            class="expense"
            :class="{ active: totalType == 'expense' }"
          >
            支出
          </span>
          <span
            v-if="ledger_type !== '投资账本'"
            @click="changeTotalType('income')"
            class="income"
            :class="{ active: totalType == 'income' }"
          >
            收入
          </span>
        </div>
      </div>
      <div class="content">
        <van-empty
          v-if="
            (totalType == 'expense' &&
              (!expense_data || !expense_data.length)) ||
            (totalType == 'income' && (!income_data || !income_data.length))
          "
          image-size="80"
          description="暂无数据"
        />
        <template v-else>
          <div
            class="item"
            v-for="item in totalType == 'expense' ? expense_data : income_data"
            :key="item.bill_type"
          >
            <div class="left">
              <div class="type">
                <span
                  :class="{
                    expense: totalType == 'expense',
                    income: totalType == 'income',
                  }"
                >
                  <i
                    class="iconfont"
                    :class="notNull(item.bill_type) ? item.bill_type_icon : 0"
                  />
                </span>
                <span>{{ item.bill_type_name }}</span>
              </div>
              <div class="progress">
                {{
                  Number(
                    (item.money /
                      Number(
                        totalType == 'expense' ? total_expense : total_income
                      )) *
                      100
                  ).toFixed(2)
                }}%
              </div>
            </div>
            <div class="right">
              <div class="percent">
                <van-progress
                  :percentage="
                    Number(
                      (item.money /
                        Number(
                          totalType == 'expense' ? total_expense : total_income
                        )) *
                        100
                    )
                  "
                  stroke-width="6px"
                  :show-pivot="false"
                  track-color="#ffffff"
                  color="#39be77"
                />
              </div>
              <div class="money">¥{{ Number(item.money).toFixed(2) || 0 }}</div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="proportion">
      <div class="head">
        <span class="title">收支构成</span>
        <div class="tab">
          <span
            @click="changePieType('expense')"
            class="expense"
            :class="{ active: pieType == 'expense' }"
            >支出</span
          >
          <span
            v-if="ledger_type !== '投资账本'"
            @click="changePieType('income')"
            class="income"
            :class="{ active: pieType == 'income' }"
          >
            收入
          </span>
        </div>
      </div>
      <div
        v-if="
          (pieType == 'expense' && (!expense_data || !expense_data.length)) ||
          (pieType == 'income' && (!income_data || !income_data.length))
        "
        class="chart-empty-wrap"
      >
        <van-empty
          :image="require('../assets/images/empty-chart.png')"
          image-size="80"
          description="暂无数据"
        />
      </div>
      <canvas id="proportion"></canvas>
    </div>
    <ledger-type-popup
      v-model:show-ledger-type="showLedgerType"
      v-model:ledger-type="ledger_type"
      :exclude-ledger="['cycle_ledger', 'toloan_ledger']"
      @onSelectLedger="selectLedger"
    />
    <PopMonth ref="popMonthRef" @selectTime="selectMonth" />
  </div>
</template>

<script lang="ts">
// import * as echarts from "echarts";
import F2 from '@antv/f2/lib/index-all';
import { onMounted, reactive, toRefs, ref, Ref, computed } from 'vue';
import dayjs from 'dayjs';
import PopMonth from 'blueprint-share/src/components/service/PopMonth.vue';
import LedgerTypePopup from 'blueprint-share/src/components/service/LedgerTypePopup.vue';
import { variables } from '@/theme/variables';
import { notNull } from 'blueprint-share/src/utils/judgeTool';
import {
  ledgerTypeDict,
  sourceTypeDict,
  SourceTypeDictTypes,
} from '@/dataDict';
import { BillReq } from 'blueprint-share/src/request/bill';
import { getFontRpx } from 'blueprint-share/src/utils/chart';

export default {
  name: 'statistics',
  components: {
    PopMonth,
    LedgerTypePopup,
  },
  setup() {
    const popMonthRef: Ref = ref(null);
    const state = reactive({
      currentMonth: dayjs().format('YYYY-MM'),
      ledger_type: '基础账本',
      total_expense: 0,
      total_income: 0,
      expense_data: [],
      income_data: [],
      totalType: 'expense',
      pieType: 'expense',
      showLedgerType: false,
    });
    onMounted(() => {
      methods.getData();
    });

    const suplus = computed(() =>
      Number(state.total_income - state.total_expense).toFixed(2)
    );

    const methods = {
      notNull,
      async getData() {
        const { data } = await BillReq.billStat({
          date: state.currentMonth,
          ledger_type: ledgerTypeDict.getByCusKey('title', state.ledger_type)
            ?.value,
        });

        // 总收支
        state.total_expense = data.total_expense;
        state.total_income = data.total_income;

        // 过滤支出和收入
        let expense_data = data.list
          .filter((item: any) =>
            sourceTypeDict.equal(SourceTypeDictTypes.EXPENSE, item.source_type)
          )
          .sort((a: any, b: any) => b.money - a.money); // 过滤出账单类型为支出的项
        let income_data = data.list
          .filter((item: any) =>
            sourceTypeDict.equal(SourceTypeDictTypes.INCOME, item.source_type)
          )
          .sort((a: any, b: any) => b.money - a.money); // 过滤出账单类型为收入的项
        state.expense_data = methods.convertOther(expense_data);
        state.income_data = methods.convertOther(income_data);
        methods.setPieChart();
      },
      // 绘制饼图方法
      setPieChart() {
        let theme: any =
          variables[localStorage.getItem('data-theme') || 'light'];

        const data =
          state.pieType == 'expense' ? state.expense_data : state.income_data;

        let ele: any = document.getElementById('proportion');
        if (!data || !data.length) {
          ele.style.display = 'none';
          return;
        } else ele.style.display = 'block';
        const total = data.reduce((acc, cur: any) => {
          return acc + parseFloat(cur.money);
        }, 0);
        data.map((v: any) => {
          v.type = v.bill_type_name;
          v.money = parseFloat(v.money);
          v.percentage = ((v.money / total) * 100).toFixed(2) + '%';
        });

        const chart = new F2.Chart({
          id: 'proportion',
          pixelRatio: window.devicePixelRatio,
        });
        chart.source(data);
        chart.coord('polar', {
          transposed: true,
          radius: 0.7,
          innerRadius: 0.7,
        });
        chart.axis(false);
        chart.legend(false);
        chart.tooltip(false);
        chart
          .interval()
          .position('const*money')
          .adjust('stack')
          .color('type', ['#1890FF', '#13C2C2', '#2FC25B', '#FACC14']);
        chart.pieLabel({
          sidePadding: 30,
          activeShape: true,
          label1: function label1(data) {
            return {
              text: '￥' + data.money,
              fill: '#343434',
              fontWeight: 'bold',
              fontSize: getFontRpx(0.8),
            };
          },
          label2: function label2(data) {
            return {
              text: data.type + data.percentage,
              fill: '#999',
              fontSize: getFontRpx(0.6),
            };
          },
          onClick: function onClick(ev) {
            const data = ev.data;
          },
        });
        chart.render();
      },
      convertOther(list: any) {
        if (list.length > 4) {
          let list2 = list.slice(0, 4);
          let otherObj: any = {
            bill_type: 0,
            bill_type_icon: 'icon-leibiefenlei',
            bill_type_name: '其它',
            money: 0,
          };
          for (let i = 4; i < list.length; i++)
            otherObj.money += Number(list[i].money);
          otherObj.money = Number(otherObj.money).toFixed(2);
          otherObj.source_type = list[0].source_type;
          return [...list2, otherObj];
        } else {
          return list;
        }
      },
    };

    const formMethods = {
      selectLedger(ledger_type: string) {
        if (ledger_type === '投资账本') {
          state.totalType = 'expense';
          state.pieType = 'expense';
        }
        methods.getData();
      },
      // 切换收支构成类型
      changeTotalType(type: string) {
        state.totalType = type;
      },
      // 切换饼图收支类型
      changePieType(type: string) {
        state.pieType = type;
        // 重绘饼图
        methods.setPieChart();
      },
      // 月份弹窗开关
      monthToggle() {
        popMonthRef.value.toggle();
      },
      selectMonth(item: string) {
        state.currentMonth = item;
        methods.getData();
      },
    };

    return {
      popMonthRef,
      suplus,
      ...toRefs(state),
      ...methods,
      ...formMethods,
    };
  },
};
</script>

<style lang="scss" scoped>
.data {
  min-height: 100%;
  background-color: #f5f5f5;
  .total {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 0;
    margin-bottom: 10px;
    .query-wrap {
      margin-bottom: 10px;

      :deep(.icon-arrow_down_fat) {
        color: var(--text-color2);
        font-size: 14px;
      }
    }
    .time {
      margin-right: 10px;
      position: relative;
      width: 96px;
      padding: 6px;
      background-color: #f5f5f5;
      color: var(--text-color1);
      border-radius: 4px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span:nth-of-type(1)::after {
        content: '';
        position: absolute;
        top: 9px;
        bottom: 8px;
        right: 28px;
        width: 1px;
        background-color: rgba(0, 0, 0, 0.5);
      }
      .van-icon-notes-o {
        font-size: 16px;
        color: var(--text-color2);
      }
    }
    .title {
      color: var(--text-color5);
      margin-bottom: 8px;
      font-size: 12px;
      font-weight: 500;
    }
    .suplus {
      font-size: 24px;
      color: var(--text-color5);
      font-weight: 600;
      margin-bottom: 16px;
    }
    .income,
    .expense {
      color: var(--text-color2);
      font-weight: 500;
    }
  }
  .structure {
    padding: 0 16px;
    background-color: #fff;
    margin-bottom: 10px;
    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0;
      .title {
        font-size: 18px;
        color: var(--text-color1);
      }
      .tab {
        span {
          display: inline-block;
          width: 40px;
          height: 24px;
          background-color: #f5f5f5;
          text-align: center;
          line-height: 24px;
          margin-left: 10px;
          border-radius: 4px;
        }
        .expense,
        .income {
          &.active {
            background: var(--bg-color3);
            color: var(--text-color6);
          }
        }
      }
    }
    .content {
      .item {
        display: flex;
        padding-bottom: 5px;
        align-items: center;
        .left {
          flex: 5;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-right: 10px;
          .type {
            display: flex;
            align-items: center;
            span:nth-of-type(1) {
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              width: 25px;
              height: 25px;
              margin-right: 10px;
              color: #fff;
              i {
                font-size: 14px;
              }
            }
            .expense,
            .income {
              background-color: var(--text-color5);
            }
          }
        }
        .right {
          width: 180px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-align: right;
          .percent {
            width: 160px;
          }
          .momey {
            width: 100px;
          }
        }
      }
    }
  }
  .proportion {
    background-color: #fff;
    padding: 0px 16px;
    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0;
      padding-bottom: 0;
      .title {
        font-size: 18px;
        color: var(--text-color1);
      }
      .tab {
        span {
          display: inline-block;
          width: 40px;
          height: 24px;
          background-color: #f5f5f5;
          text-align: center;
          line-height: 24px;
          margin-left: 10px;
          border-radius: 4px;
        }
        .expense,
        .income {
          &.active {
            background: var(--bg-color3);
            color: var(--text-color6);
          }
        }
      }
    }
    .chart-empty-wrap {
      @include flex-row-all-center();
      width: 100%;
      height: 400px;
    }
    #proportion {
      width: 100%;
      height: 400px;
    }
  }
}
</style>
