import { BaseObject } from 'blueprint-share/src/types/utils/baseTypes';
import {
  AxiosHttpResponse,
  HttpError,
  HttpResponse,
  RequestConfig,
  ResponseData,
} from 'blueprint-share/src/types/utils/http';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { isNull } from "blueprint-share/src/utils/judgeTool";
import { RequestInitConfig } from 'blueprint-share/src/types/utils/http.d';

axios.defaults.withCredentials = true;

const instance = axios.create({ 
  timeout: 60 * 1000,
  headers: { 'Content-Type': 'application/json' },
});

/**
 * http、https请求类
 */
export class Request {
  modulePrefix: string = '';

  instance: AxiosInstance;

  opts: AxiosRequestConfig = {};

  constructor(opts?: RequestInitConfig) {
    this.modulePrefix = opts?.modulePrefix || '';
    this.instance = opts?.instance || instance;
  }

  /**
   * 做一下初始化配置
   */
  static init(opts = { baseURL: '' }) {
    instance.defaults.baseURL = opts.baseURL;
  }

  /**
   * 添加请求拦截器
   */
  static addRequestInterceptor(
    fn: (value: RequestConfig) => RequestConfig | Promise<RequestConfig>,
    errorFn: (error: RequestConfig) => any
  ) {
    instance.interceptors.request.use(fn, errorFn);
  }

  /**
   * 添加响应拦截器
   */
  static addResponseInterceptor(
    fn: (
      value: AxiosHttpResponse<ResponseData<any>>
    ) => AxiosHttpResponse<any> | Promise<AxiosHttpResponse<any>>,
    errorFn: (error: HttpError) => any
  ) {
    instance.interceptors.response.use(fn, errorFn);
  }

  /**
   * 发送get请求
   * @param {*} api 请求接口  {url:"",query:[]}
   * @param {*} params {}
   * @param {*} reqConfig 配置对象
   * @returns Promise
   */
  get<T>(
    api: string,
    params: BaseObject = {},
    reqConfig: RequestConfig = {
      headers: {},
    }
  ): Promise<HttpResponse<ResponseData<T>>> {
    api = isNull(this.modulePrefix) ? api : this.modulePrefix + api;

    return this.instance.get(api, {
      params: { ...params },
      ...reqConfig,
    });
  }

  /**
   * post请求方法
   * @param {*} api 请求路径
   * @param {*} data 请求数据
   * @param {*} reqConfig 请求配置
   */
  post<T>(
    api: string,
    data: BaseObject = {},
    reqConfig: RequestConfig = {
      headers: {},
    }
  ): Promise<HttpResponse<ResponseData<T>>> {
    api = isNull(this.modulePrefix) ? api : this.modulePrefix + api;

    return this.instance.post(api, data, reqConfig);
  }

  upload<T>(
    api: string,
    data: BaseObject = {},
    reqConfig: RequestConfig = {
      headers: {},
    }
  ): Promise<HttpResponse<ResponseData<T>>> {
    let formData = new FormData();
    for (const key in data) formData.append(key, data[key]);

    api = isNull(this.modulePrefix) ? api : this.modulePrefix + api;
    let axiosConfig: any = {
      url: api,
      method: 'post',
      data: formData,
      ...reqConfig,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...reqConfig.headers,
      },
    };

    return this.instance.request(axiosConfig);
  }

  /**
   * 获取axios实例
   */
  getInstance() {
    return this.instance;
  }
}
