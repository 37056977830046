<template>
  <div class="invest-page">
    <div class="invest-page-wrap">
      <header class="top-header">
        <div class="header-row">
          <div class="mini-card">
            <div>
              <icon icon-class="icon-niandu" />当年总收益
            </div>
            <div>￥{{ statData.yearProfit }}</div>
            <div v-if="Number(statData.yearProfitPercent)" :class="getCompareIconClass(statData.yearProfitPercent)">
              <icon :icon-class="getCompareIconClass(statData.yearProfitPercent, 'icon')
                " />
              {{ statData.yearProfitPercent }}%
            </div>
          </div>
          <div class="mini-card">
            <div>
              <icon icon-class="icon-yuebao" />当月总收益
            </div>
            <div>￥{{ statData.monthProfit }}</div>
            <div v-if="Number(statData.monthProfitPercent)" :class="getCompareIconClass(statData.monthProfitPercent)">
              <icon :icon-class="getCompareIconClass(statData.monthProfitPercent, 'icon')
                " />
              {{ statData.monthProfitPercent }}%
            </div>
          </div>
          <div class="mini-card">
            <div>
              <icon icon-class="icon-jiaoyi" />当年卖出交易
            </div>
            <div>{{ statData.sellCount }}</div>
            <div v-if="Number(statData.yearSellCountPercent)"
              :class="getCompareIconClass(statData.yearSellCountPercent)">
              <icon :icon-class="getCompareIconClass(statData.yearSellCountPercent, 'icon')
                " />
              {{ statData.yearSellCountPercent }}%
            </div>
          </div>
        </div>
        <div class="header-row">
          <div class="mini-card">
            <div>
              <icon icon-class="icon-bili" />当年收益率
            </div>
            <div class="profit-percent-value">
              <span> {{ statData.currYearProfitPercent }}% </span>
            </div>
          </div>
          <div class="mini-card">
            <div>
              <icon icon-class="icon-qian" />总持仓金额
            </div>
            <div>￥{{ statData.holdMoney }}</div>
          </div>
          <div class="mini-card">
            <div>
              <icon icon-class="icon-jiaoyi" />持仓交易数
            </div>
            <div>{{ statData.holdCount }}</div>
          </div>
        </div>
      </header>

      <div class="content">
        <div class="card">
          <header>
            <icon icon-class="icon-chicang" />当前持仓分类
          </header>
          <div id="CurrPositionPie"></div>
        </div>
        <div class="card">
          <header>
            <icon icon-class="icon-shouyi" />当年收益趋势
          </header>
          <div id="PorfitTrendLine"></div>
        </div>
        <div class="card">
          <header>
            <icon icon-class="icon-icon" />收益分类
          </header>
          <div id="PorfitPie"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  onUnmounted,
  toRefs,
  onActivated,
} from 'vue';
import * as echarts from 'echarts';
import {
  genCurrPositionOption,
  genProfitTrendLine,
} from 'blueprint-share/src/utils/chart/investChartOptions';
import { isNull, notNull } from 'blueprint-share/src/utils/judgeTool';
import { BillReq } from 'blueprint-share/src/request/bill';

let currPositionPie: echarts.ECharts;

let profitPie: echarts.ECharts;

let porfitTrendLine: echarts.ECharts;

export default defineComponent({
  name: 'invest',
  components: {},
  props: {},
  setup(props: any, ctx: any): any {
    const state: any = reactive({
      statData: {
        monthProfit: 0,
        yearProfit: 0,
        sellCount: 0,
        holdMoney: 0,
        holdCount: 0,
        sumInvestMoney: 0,
      },
    });

    onMounted(() => {
      console.log('???');

      methods.initCurrPositionPie();
      methods.initProfitTrendLine();
      methods.initProfitPie();

      methods.initData();
    });

    onUnmounted(() => {
      currPositionPie.dispose();
      porfitTrendLine.dispose();
      profitPie.dispose();
    });

    const methods = {
      notNull,
      initData() {
        BillReq.investStat().then((res) => {
          let statData: any = res.data;
          let { yearProfit, sumInvestMoney, holdMoney } = statData;
          let currYearProfitPercent = Number(
            (yearProfit / sumInvestMoney) * 100
          ).toFixed(2);

          if (isNaN(Number(currYearProfitPercent))) currYearProfitPercent = '0';

          state.statData = {
            ...statData,
            currYearProfitPercent,
          };

          methods.renderCurrPositionPie(statData.holdGroup, holdMoney);
          methods.renderInitProfitTrendLine(statData.monthProfitList);
          methods.renderProfitPie(
            statData.profitGroup,
            statData.profitGroup.reduce(
              (v: any, n: any) => v + Number(n.money),
              0
            )
          );
        });
      },
      initCurrPositionPie() {
        let ele: any = document.getElementById('CurrPositionPie');
        currPositionPie = echarts.init(ele);
      },
      renderCurrPositionPie(data: Array<any>, total: number) {
        currPositionPie.setOption(genCurrPositionOption(data, total));
      },
      initProfitPie() {
        let ele: any = document.getElementById('PorfitPie');
        profitPie = echarts.init(ele);
      },
      renderProfitPie(data: Array<any>, total: number) {
        profitPie.setOption(genCurrPositionOption(data, total));
      },
      initProfitTrendLine() {
        let ele: any = document.getElementById('PorfitTrendLine');
        porfitTrendLine = echarts.init(ele);
      },
      renderInitProfitTrendLine(data: Array<any>) {
        porfitTrendLine.setOption(genProfitTrendLine(data));
      },
      getCompareIconClass(v: number | string, type: string) {
        if (isNull(v)) return;
        if (type === 'icon') {
          return v >= 0 ? 'icon-iconup' : 'icon-icondown';
        } else {
          return v >= 0 ? 'iconup' : 'icondown';
        }
      },
    };

    return { ...toRefs(state), ...methods };
  },
});
</script>

<style lang="scss" scoped>
.invest-page {
  min-height: 100vh;
}

.invest-page-wrap {
  padding: 10px;

  .card {
    background: var(--bg-color4);
    padding: 6px;
    padding-top: 10px;
    border-radius: 5px;
    // box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 12px;

    &:nth-child(1) {
      &>header {
        :deep(.icon) {
          margin-right: 1px;
          font-size: 26px;
        }
      }
    }

    &>header {
      font-size: 14px;
      color: var(--text-color1);

      @include flex-row-all-center();
      justify-content: flex-start;

      :deep(.icon) {
        margin-right: 5px;
        font-size: 24px;
      }
    }
  }
}

.top-header {
  .header-row {
    @include flex-row-all-center();
    justify-content: space-between;

    margin-bottom: 10px;

    .mini-card {
      width: 110px;
      height: 120px;
      background: var(--bg-color4);
      border-radius: 5px;
      @include flex-column-all-center();

      &>div {
        @include flex-row-all-center();
        margin-bottom: 8px;
      }

      &>div:nth-child(1) {
        :deep(.icon) {
          font-size: 20px;
          margin-right: 5px;
        }
      }

      &>div:nth-child(2) {
        font-size: 18px;
        color: var(--text-color1);
      }

      .iconup {
        color: #3abb78;
      }

      .icondown {
        color: #d6696a;
      }

      &>div:nth-child(3) {
        margin-bottom: 0;

        :deep(.icon) {
          font-size: 18px;
          margin-right: 0px;
        }

        :deep(.icon-iconup) {
          color: #3abb78;
        }

        :deep(.icon-icondown) {
          color: #d6696a;
        }
      }

      .down-wrap {
        color: #d6696a !important;
      }
    }
  }
}

.profit-percent-value {
  span:nth-child(1) {
    color: var(--text-color4);
  }
}

.content {
  padding-bottom: 50px;
}

#CurrPositionPie,
#PorfitPie,
#PorfitTrendLine {
  height: 300px;
}
</style>
