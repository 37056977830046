import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_van_popover = _resolveComponent("van-popover")!
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_van_nav_bar, {
      title: $props.pageTitle || _ctx.title,
      "left-text": "返回",
      "left-arrow": "",
      onClickLeft: _ctx.back,
      "safe-area-inset-top": "",
      fixed: "",
      right: ""
    }, {
      right: _withCtx(() => [
        _createVNode(_component_icon, {
          "icon-class": "icon-icon_home",
          onClick: _ctx.toHome
        }, null, 8, ["onClick"]),
        _createVNode(_component_van_popover, {
          show: _ctx.showPopover,
          "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showPopover) = $event)),
          theme: "dark",
          placement: "bottom-end",
          actions: _ctx.actions,
          onSelect: _ctx.selectPopover
        }, {
          reference: _withCtx(() => [
            _createVNode(_component_icon, { "icon-class": "icon-leibiefenlei" })
          ]),
          _: 1
        }, 8, ["show", "actions", "onSelect"])
      ]),
      _: 1
    }, 8, ["title", "onClickLeft"]),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}