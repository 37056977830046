<template>
  <div class="wrap">
    <div class="header">
      <div class="top-header">
        <div class="flex-row-all-center jus-between">
          <div class="type-wrap" @click="PopTypeRefToggle">
            <span class="title">{{ currentSelect.name || '全部类型' }}</span>
            <van-icon name="apps-o" />
          </div>
          <div class="flex-row-all-center" @click="showLedgerType = true">
            <span style="margin-right: 3px">{{ ledger_type }}</span>
            <icon icon-class="icon-arrow_down_fat" />
          </div>
        </div>
        <div class="data-wrap">
          <span class="time" @click="PopMonthRefToggle"
            >{{ currentTime }}<van-icon name="arrow-down" />
          </span>
          <span class="expense">总支出 ¥{{ totalExpense }}</span>
          <span class="income">总收入 ¥{{ totalIncome }}</span>
        </div>
      </div>
      <div class="top-nav-wrap">
        <div class="top-nav flex-row-all-center jus-evenly">
          <div
            class="nav-item flex-column-all-center"
            v-for="(nav, index) in navList"
            :key="nav.title"
            @click="toPage(nav)"
          >
            <icon :icon-class="nav.icon" />
            <span style="padding-top: 5px">{{ nav.title }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content-wrap">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <template #pulling="props">
          <img class="doge" src="../assets/loading.gif" />
        </template>
        <!-- 释放提示 -->
        <template #loosing>
          <img class="doge" src="../assets/loading.gif" />
        </template>
        <!-- 加载提示 -->
        <template #loading>
          <img class="doge" src="../assets/loading.gif" />
        </template>
        <div class="list-wrap">
          <van-list
            v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <!-- 加载提示 -->
            <template #loading>
              <img class="doge" src="../assets/loading.gif" />
            </template>
            <CardItem v-for="(v, i) in list" :key="i" :bill="v"></CardItem>
          </van-list>
        </div>
      </van-pull-refresh>
    </div>

    <ledger-type-popup
      v-model:show-ledger-type="showLedgerType"
      v-model:ledger-type="ledger_type"
      :exclude-ledger="['cycle_ledger']"
      @onSelectLedger="onSelectLedger"
    />

    <all-budget
      v-model:visible="budgetDialogVisible"
      type="add"
      @saveDone="confirmBudget"
    />

    <PopType ref="PopTypeRef" @selectType="selectType"></PopType>
    <PopMonth ref="PopMonthRef" @selectTime="selectTime"></PopMonth>
    <PopAdd ref="PopAddRef" @refresh="onRefresh"></PopAdd>
  </div>
</template>

<script lang="ts">
import dayjs from 'dayjs';
import {
  reactive,
  toRefs,
  onMounted,
  ref,
  Ref,
  onUnmounted,
  nextTick,
} from 'vue';
import CardItem from 'blueprint-share/src/components/service/CardItem.vue';
import PopType from 'blueprint-share/src/components/service/PopType.vue';
import PopMonth from 'blueprint-share/src/components/service/PopMonth.vue';
import PopAdd from 'blueprint-share/src/components/service/PopAdd.vue';
import LedgerTypePopup from 'blueprint-share/src/components/service/LedgerTypePopup.vue';
import AllBudget from 'blueprint-share/src/components/service/AllBudget.vue';
import { useRoute, useRouter } from 'vue-router';
import { commonEmitter } from 'blueprint-share/src/utils/mitt';
import { CommonEventType } from 'blueprint-share/src/utils/mitt/eventType';
import { useLoading } from '@/utils/hooks';
import { ledgerTypeDict } from '@/dataDict';
import { notNull } from 'blueprint-share/src/utils/judgeTool';
import { useStore } from '@/store';
import { BillReq } from 'blueprint-share/src/request/bill';
import { BudgetReq } from 'blueprint-share/src/request/budget';

interface NavItem {
  title: string;
  icon: string;
  link: string;
}

export default {
  name: 'home',
  components: {
    CardItem,
    PopType,
    PopMonth,
    PopAdd,
    LedgerTypePopup,
    AllBudget,
  },
  setup() {
    const route = useRoute();
    const PopTypeRef: Ref = ref(null);
    const PopMonthRef: Ref = ref(null);
    const PopAddRef: Ref = ref(null);
    const budgetInputRef: Ref = ref(null);
    const router = useRouter();
    const store = useStore();

    const state: any = reactive({
      currentSelect: {},
      ledger_type: store.state.homeSearchLedgerType,
      currentTime: dayjs().format('YYYY-MM'),
      totalExpense: 0.0,
      totalIncome: 0.0,
      totalPage: 0,
      page: 0,
      page_size: 5,
      list: [],
      navList: [
        { title: '预算', icon: 'icon-yusuanguanli', link: '/budget' },
        { title: '借贷', icon: 'icon-jiedai', link: '/toloan_list' },
        { title: '图表', icon: 'icon-tubiao-zhuzhuangtu' },
        { title: '收入分析', icon: 'icon-shujufenxi-liuliangfenxi' },
        { title: '更多', icon: 'icon-gengduo' },
      ],
      loading: false,
      finished: false,
      refreshing: false,
      canToBudget: false,
      budgetDialogVisible: false,
      showLedgerType: false,
      allBudget: '',
    });

    onMounted(async () => {
      if (route.params.doAdd) {
        PopAddRef.value.toggle();
      }
      commonEmitter.on(CommonEventType.ShowAddBill, () => {
        PopAddRef.value.toggle();
      });
      methods.getMyAllBudget();
    });

    onUnmounted(() => {
      commonEmitter.off(CommonEventType.ShowAddBill);
    });

    const methods = {
      getMyAllBudget() {
        BudgetReq.myAllBudget().then((res) => {
          if (notNull(res.data) && notNull(res.data.money))
            state.canToBudget = true;
        });
      },
      async getBillList() {
        if (state.refreshing) {
          state.list = [];
          state.refreshing = false;
        }
        const params = {
          date: state.currentTime,
          page: state.page,
          page_size: state.page_size,
          bill_type:
            state.currentSelect.id === 'all' ? '' : state.currentSelect.id,
          ledger_type: ledgerTypeDict.getByCusKey('title', state.ledger_type)
            ?.value,
        };
        const { data } = await BillReq.billList(params);
        state.totalExpense = data.totalExpense;
        state.totalIncome = data.totalIncome;
        state.totalPage = data.totalPage;
        state.list = state.list.concat(data.list);
        state.loading = false;
        if (state.page >= state.totalPage) {
          state.finished = true;
        }
      },
      onLoad() {
        state.page = state.page + 1;
        methods.getBillList();
      },
      onRefresh() {
        state.finished = false;
        state.page = 0;
        state.loading = true;
        state.refreshing = true;
        methods.onLoad();
      },
      // 跳转指定页面
      toPage(nav: NavItem) {
        if (nav.title !== '预算') return router.push(nav.link);

        if (state.canToBudget) {
          router.push(nav.link);
        } else {
          state.budgetDialogVisible = true;
          nextTick(() => {
            budgetInputRef.value.focus();
          });
        }
      },
    };

    const formMethods = {
      onSelectLedger(ledger_type: string) {
        store.commit('setHomeSearchLedgerType', ledger_type);
        methods.onRefresh();
      },
      //类型弹窗切换
      PopTypeRefToggle() {
        PopTypeRef.value.toggle();
      },
      //时间弹窗切换
      PopMonthRefToggle() {
        PopMonthRef.value.toggle();
      },
      //时间弹窗切换
      addToggle() {
        PopAddRef.value.toggle();
      },
      //选择类型
      selectType(item: string) {
        state.currentSelect = item;
        methods.onRefresh();
      },
      //选择时间
      selectTime(item: string) {
        state.currentTime = item;
        methods.onRefresh();
      },
      // 保存预算
      confirmBudget() {
        router.push('/budget');
      },
    };

    return {
      PopTypeRef,
      PopMonthRef,
      PopAddRef,
      budgetInputRef,
      ...toRefs(state),
      ...methods,
      ...formMethods,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 80px;
  .header {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 80px;
    background: var(--bg-color3);
    color: var(--text-color7);
    font-size: 14px;
    z-index: 100;
    padding-bottom: 30px;
    .top-header {
      padding: 10px;
    }
    .type-wrap {
      background-color: var(--btn-color1);
      display: inline-block;
      padding: 6px;
      border-radius: 4px;
      position: relative;
      align-self: baseline;
      border: 1px solid var(--border-color1);
      .title {
        margin-right: 22px;
      }
      .title::after {
        content: '';
        position: absolute;
        top: 12px;
        bottom: 11px;
        right: 32px;
        width: 1px;
        background-color: #e9e9e9;
      }
    }
    .data-wrap {
      margin-top: 10px;
      font-size: 13px;
      .time {
        margin-right: 12px;
        .sort-down {
          font-size: 12px;
        }
      }
      .expense {
        margin-right: 10px;
      }
    }
  }
  .top-nav-wrap {
    width: 100%;
    padding: 0px 10px;
    box-sizing: border-box;
    .top-nav {
      box-sizing: border-box;
      width: calc(100% - 20px);
      height: 60px;
      background: var(--bg-color4);
      border-radius: 5px;
      position: absolute;
      bottom: -25px;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
    }

    .nav-item {
      :deep(.icon) {
        font-size: 28px;
        // color: var(--text-color1);
      }
      span {
        color: rgba(0, 0, 0, 0.8);
        font-size: 12px;
      }
    }
  }

  :deep(.icon-arrow_down_fat) {
    color: var(--text-color7);
    font-size: 14px;
  }
  :deep(.van-pull-refresh__head) {
    padding-bottom: 35px;
  }
  .doge {
    width: 100px;
  }
  .content-wrap {
    width: 100%;
    height: 100%;
    padding: 10px;
    padding-top: 65px;
    box-sizing: border-box;
    overflow-y: auto;

    .list-wrap {
      min-height: 73vh;
    }
  }
}
</style>
