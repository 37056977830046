import CURR_ENV from '@/utils/configure';
import { Request } from '@/utils/http/httpRequest';
import { showToast, showNotify, closeToast } from 'vant';
const loadingIcon = require('@/assets/images/loading.gif');
import storage from '../storage';
import { isNull } from '../judgeTool';
import router from '@/router';
import { AxiosInstance } from 'axios';

const noCheckTokenApis = ['/users/login', '/users/register'];

Request.init({ baseURL: CURR_ENV.requestUrl });

Request.addRequestInterceptor(
  function (config) {
    let token = storage.getItem('token');
    config.headers['token'] = token || null;
    return config;
  },
  (error) => {
    closeToast();
    Promise.reject(error);
  }
);

Request.addResponseInterceptor(
  function (response) {
    closeToast();
    const { data } = response;
    if (typeof response.data !== 'object') {
      showNotify({ type: 'danger', message: '服务端错误' });
      return Promise.reject(data);
    }
    if (data.code != 200) {
      if (data.msg) showToast({ message: data.msg, duration: 2000 });
      if (data.code == 9002) {
        //未登录
        router.push('/login');
        storage.setItem('token', null);
        return Promise.reject(data.msg);
      }
      return Promise.reject(data.msg);
    }

    return Promise.resolve(data);
  },
  (error) => {
    let { status, statusText } = error.response || {};
    let config = error.response?.config;
    if (config && config.xhrErrorFallback) {
      config.xhrErrorFallback(error);
      return Promise.reject(error);
    }
    switch (status) {
      case 401:
        storage.removeItem('token');
        showToast({
          message: '请重新登录',
          onClose() {
            router.push('/login');
            storage.setItem('token', null);
          },
        });
        break;
      case 504:
        showToast(statusText);
        break;
    }
    return Promise.reject(error);
  }
);

export const appRequest = new Request();

export const billRequest = new Request({
  modulePrefix: '/bill',
});

export const billTypesRequest = new Request({
  modulePrefix: '/bill_types',
});

export const usersRequest = new Request({
  modulePrefix: '/users',
});

export const budgetRequest = new Request({
  modulePrefix: '/budget',
});

export const targetRequest = new Request({
  modulePrefix: '/target',
});

export const targetTypeRequest = new Request({
  modulePrefix: '/target_type',
});
