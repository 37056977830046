import { showToast } from 'vant';
import { ref } from 'vue';
import { notNull } from 'blueprint-share/src/utils/judgeTool';

export function useLoading(useshowToast: boolean | string | void = false): any {
  const loading = ref<boolean>(false);
  let toast: any = null;

  const run = (callback: Function) => {
    return (...args: any) => {
      if (loading.value) return;
      loading.value = true;
      if (notNull(useshowToast))
        toast = showToast({
          type: 'loading',
          forbidClick: true,
          message: typeof useshowToast === 'string' ? useshowToast : '加载中...',
        });
      return callback && callback.apply({}, args);
    };
  };

  const done = () => {
    loading.value = false;
    if (notNull(useshowToast) && toast) toast.clear();
  };
  return [loading, run, done];
}
