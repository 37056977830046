import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import registerVant from '@/utils/vant';
import '@/styles/reset.css';
import changeTheme from '@/theme/theme';
import CommonComponents from 'blueprint-share/src/components/common/index';
import { store, key } from '@/store';
import { showToast, allowMultipleToast } from 'vant';

let theme = localStorage.getItem('data-theme') || 'light';

changeTheme(theme);

const app: any = createApp(App);

app.config.productionTip = false;

app.config.globalProperties.$toast = showToast;

app.use(router).use(store, key).use(CommonComponents).mount('#app');

allowMultipleToast();

registerVant(app);
