import { EnvConfig } from 'blueprint-share/src/types/utils/configure';

const ENV_TYPE = {
  PRD: 'prd',
  STAG: 'stag',
  DEV: 'dev',
  LOCAL: 'local',
};

var CURR_ENV_TYPE = process.env.VUE_APP_MAIN_ENV;

const ENV_BASE: any = {
  type: CURR_ENV_TYPE,
  [ENV_TYPE.LOCAL]: {
    appPrefix: '/',
  },
  /*开发环境*/
  [ENV_TYPE.DEV]: {
    appPrefix: '/',
  },
  [ENV_TYPE.STAG]: {
    appPrefix: './',
  },
  [ENV_TYPE.PRD]: {
    appPrefix: './',
  },
};

const CURR_ENV_BASE = ENV_BASE[ENV_BASE.type].appPrefix;

const ENV: any = {
  type: CURR_ENV_TYPE,
  /*开发环境*/
  [ENV_TYPE.LOCAL]: {
    name: ENV_TYPE.LOCAL,
    requestUrl: '/apiv1/front',
  },
  /*开发环境*/
  [ENV_TYPE.DEV]: {
    name: ENV_TYPE.DEV,
    requestUrl: '/apiv1/front',
  },
  /*回归环境 */
  [ENV_TYPE.STAG]: {
    name: ENV_TYPE.STAG,
    requestUrl: 'http://localhost:8888/apiv1/front',
    imgUrlPrefix: CURR_ENV_BASE + 'images/',
  },
  /*生产环境*/
  [ENV_TYPE.PRD]: {
    name: ENV_TYPE.PRD,
    requestUrl: 'https://www.shengcai.online/apiv1/front',
    imgUrlPrefix: CURR_ENV_BASE + 'images/',
  },
};

const CURR_ENV: EnvConfig = ENV[ENV.type];

export const getRpImgUrl = (url: string) => CURR_ENV.imgUrlPrefix + url;

export { ENV_TYPE };
export default CURR_ENV;
