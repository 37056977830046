<template>
  <van-cell-group class="item">
    <div class="header-date">
      <div>
        {{ bill.bill_date }}
        <span style="margin-left: 12px">{{ billDateWeek }}</span>
      </div>
      <div class="money">
        <span v-if="expense"><b>支</b>{{ expense.toFixed(2) }}</span>
        <span v-if="income"><b>收</b>{{ income.toFixed(2) }}</span>
      </div>
    </div>
    <van-cell
      v-for="(v, i) in bill.bills"
      :key="i"
      :label="`${v.remark ? v.remark : ''}`"
      @click="goToDetail(v)"
    >
      <template #title>
        <div class="flex-row-center">
          <template v-if="!isTotoanLedger(v)">
            <icon :icon-class="v.bill_type_icon" wrap-class="icon-container" />
            <span class="custom-title" style="margin-left: 5px">{{
              v.bill_type_name
            }}</span>
          </template>
          <template v-if="isTotoanLedger(v)">
            <h2>{{ renderToloanText(v) }}：{{ v.borrower }}</h2>
          </template>
        </div>
      </template>
      <template #value>
        <div>
          <p>{{ `${renderSourceTypeText(v)}${Number(v.money).toFixed(2)}` }}</p>
          <p class="sell-money" v-if="showSellMoney(v)">
            卖出{{ Number(v.sell_money).toFixed(2) }}
          </p>
        </div>

        <van-tag
          v-if="v.holding_status"
          :color="holdingStatusTagColor[v.holding_status]"
          plain
          >{{ renderStatusTag(v) }}</van-tag
        >
      </template>
    </van-cell>
  </van-cell-group>
</template>

<script lang="ts">
import { reactive, toRefs, computed } from 'vue';
import { useRouter } from 'vue-router';
import { weekFormat } from '../../utils/date/index';
import dayjs from 'dayjs';
import {
  sourceTypeDict,
  SourceTypeDictTypes,
  holdingStatusDict,
  ledgerTypeDict,
  LedgerTypeDictTypes,
  HoldingStatusTypes,
  cycleTaskStatusDict,
  CycleTaskStatusTypes,
} from '@/dataDict';
import { notNull } from '../../utils/judgeTool';
import { BaseObject } from '../../utils/baseTypes';

const holdingStatusTagColor = {
  1: '#8ebee2',
  2: '#7232dd',
};

const toloanTitle: any = {
  1: '借出',
  2: '借入',
};

const sourceText: any = {
  1: '支',
  2: '收',
};
const toloanText: any = {
  1: '借款人',
  2: '出借人',
};

export default {
  name: 'CardItem',
  props: {
    bill: {
      type: Object,
      default: {},
    },
  },
  setup(props: any) {
    const router = useRouter();
    const state = reactive({
      income: 0,
      expense: 0,
      holdingStatusTagColor,
    });
    //总收入
    state.income = props.bill.income;
    //总支出
    state.expense = props.bill.expense;

    const computeds = {
      billDateWeek: computed(
        () => '星期' + weekFormat(dayjs(props.bill.bill_date).day())
      ),
    };
    const methods = {
      notNull,
      isTotoanLedger(v: BaseObject) {
        return ledgerTypeDict.equal(
          LedgerTypeDictTypes.TOTOAN_LEDGER,
          v.ledger_type
        );
      },
      timeFomater(time: string) {
        return dayjs(time).format('HH:mm');
      },
      goToDetail(item: any) {
        router.push({
          path: '/detail',
          query: {
            id: item.id,
          },
        });
      },
      showSellMoney(v: any) {
        return (
          ledgerTypeDict.equal(
            LedgerTypeDictTypes.INVEST_LEDGER,
            v.ledger_type
          ) &&
          holdingStatusDict.equal(HoldingStatusTypes.SOLD, v.holding_status)
        );
      },
      renderSourceTypeText(v: any) {
        if (
          ledgerTypeDict.equal(LedgerTypeDictTypes.TOTOAN_LEDGER, v.ledger_type)
        )
          return toloanTitle[v.source_type];
        return sourceTypeDict.getByVal(v.source_type)?.title;
      },
      renderToloanText(v: any) {
        return toloanText[v.source_type];
      },
      renderStatusTag(v: any) {
        if (
          ledgerTypeDict.equal(LedgerTypeDictTypes.INVEST_LEDGER, v.ledger_type)
        )
          return holdingStatusDict.getByVal(v.holding_status)?.title;
        if (
          ledgerTypeDict.equal(LedgerTypeDictTypes.CYCLE_LEDGER, v.ledger_type)
        )
          return cycleTaskStatusDict.getByVal(v.holding_status)?.title;
      },
    };

    return {
      ...toRefs(state),
      ...computeds,
      ...methods,
    };
  },
};
</script>

<style lang="scss" scoped>
.item {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  .header-date {
    height: 60px;
    display: flex;
    background-color: var(--bg-color4);
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    font-size: 12px;
    div {
      color: var(--text-color1);
    }
    .money {
      span {
        margin-left: 20px;
        b {
          color: var(--text-color1);
          font-weight: normal;
          // background-color: var(--bg-color1);
          padding: 3px;
          margin-right: 2px;
        }
      }
    }
  }
  .sell-money {
    color: var(--text-color4);
  }
  :deep(.van-cell__label) {
    overflow: hidden;
    width: 160px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  :deep(.icon-wrap) {
    @include flex-row-all-center();
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: var(--bg-color5);
    .icon {
      color: var(--text-color6);
      font-size: 16px;
    }
  }
}
</style>
