import { BaseObject } from 'blueprint-share/src/utils/baseTypes';

export let variables: BaseObject = {
  light: {
    '--bg-color1': '#f5f5f5',
    '--bg-color2': '#f9f9f9',
    '--bg-color3': '#ffda44',
    '--bg-color4': '#ffffff',
    '--bg-color5': '#ffda44',
    '--bg-color6': '#fae89f',
    '--bg-color7': 'linear-gradient( 0deg, #FDEB71 10%, #F8D800 100%)',
    '--bg-color8': 'rgba(0,0,0,0.1)',
    '--text-color1': '#000000',
    '--text-color2': 'rgba(0,0,0,.5)',
    '--text-color3': 'rgba(0,0,0,.3)',
    '--text-color4': 'rgb(236, 190, 37)',
    '--text-color5': '#ffda44',
    '--text-color6': '#ffffff',
    '--text-color7': '#1c1d20',
    '--text-color8': '#585858',
    '--border-color': '#f5f5f5',
    '--border-color1': '#8c7b3b',
    '--btn-color1': 'linear-gradient( 135deg, #FDEB71 10%, #F8D800 100%)',
    '--task-color-primary': '#7766e7',
    '--task-color-danger': '#fe7488',
    '--task-color-wraning': '#ffcd00',
    '--task-color-success': '#1ebd85',
    '--task-color-info': '#518bf1',
    '--task-bg-image1': 'linear-gradient(135deg, #c0baea 10%, #7766e7 100%)',
    '--task-color-text1': '#333',
    '--task-color-text2': '#666',
    '--task-color-text3': '#999',
    '--task-space-large': '20px',
    '--task-space': '16px',
    '--task-space-small': '12px',
    '--task-space-mini': '10px',
    '--task-raidus-larger': '16px',
    '--task-radius': '10px',
    '--task-font-size-large': '16px',
    '--task-font-size': '14px',
    '--task-font-size-small': '12px',
  },
  green: {
    '--bg-color1': '#f5f5f5',
    '--bg-color2': '#f9f9f9',
    '--bg-color3': '#39be77',
    '--bg-color4': '#ffffff',
    '--bg-color5': '#39be77',
    '--bg-color6': '#73c097',
    '--bg-color7': 'linear-gradient( 0deg, #81FBB8 10%, #28C76F 100%),',
    '--bg-color8': 'rgba(0,0,0,0.1)',
    '--text-color1': 'rgba(0,0,0,.9)',
    '--text-color2': 'rgba(0,0,0,.5)',
    '--text-color3': 'rgba(0,0,0,.3)',
    '--text-color4': 'rgb(236, 190, 37)',
    '--text-color5': '#39be77',
    '--text-color6': '#ffffff',
    '--text-color7': '#ffffff',
    '--text-color8': '#585858',
    '--border-color': '#f5f5f5',
    '--border-color1': '#ffffff',
    '--btn-color1': 'linear-gradient( 135deg, #81FBB8 10%, #28C76F 100%),',
  },
  pink: {
    '--bg-color1': '#f5f5f5',
    '--bg-color2': '#f9f9f9',
    '--bg-color3': '#faafaa',
    '--bg-color4': '#ffffff',
    '--bg-color5': '#faafaa',
    '--bg-color6': '#faafaa',
    '--bg-color7': 'linear-gradient( 0deg, #FFD3A5 10%, #FD6585 100%)',
    '--bg-color8': 'rgba(0,0,0,0.1)',
    '--text-color1': 'rgba(0,0,0,.9)',
    '--text-color2': 'rgba(0,0,0,.5)',
    '--text-color3': 'rgba(0,0,0,.3)',
    '--text-color4': 'rgb(236, 190, 37)',
    '--text-color5': '#faafaa',
    '--text-color6': '#ffffff',
    '--text-color7': '#ffffff',
    '--text-color8': '#585858',
    '--border-color': '#f5f5f5',
    '--border-color1': '#ffffff',
    '--btn-color1': 'linear-gradient( 135deg, #FFD3A5 10%, #FD6585 100%)',
  },
  blue: {
    '--bg-color1': '#f5f5f5',
    '--bg-color2': '#f9f9f9',
    '--bg-color3': '#87ceeb',
    '--bg-color4': '#ffffff',
    '--bg-color5': '#87ceeb',
    '--bg-color6': '#87ceeb',
    '--bg-color7': 'linear-gradient( 0deg, #2AFADF 10%, #4C83FF 100%),',
    '--bg-color8': 'rgba(0,0,0,0.1)',
    '--text-color1': 'rgba(0,0,0,.9)',
    '--text-color2': 'rgba(0,0,0,.5)',
    '--text-color3': 'rgba(0,0,0,.3)',
    '--text-color4': 'rgb(236, 190, 37)',
    '--text-color5': '#87ceeb',
    '--text-color6': '#ffffff',
    '--text-color7': '#ffffff',
    '--text-color8': '#585858',
    '--border-color': '#f5f5f5',
    '--border-color1': '#ffffff',
    '--btn-color1': 'linear-gradient( 135deg, #2AFADF 10%, #4C83FF 100%),',
  },

  // dark: {},
};
