import { getFontRpx } from './index';

export function genCurrPositionOption(
  echartData: Array<any>,
  total: number
): any {
  let bgColor = '#fff';
  let title = '总量';
  let color = [
    '#c065e7',
    '#765deb',
    '#3862d8',
    '#6a89E2',
    '#219CF9',
    '#6efbbf',
    '#40c057',
    '#ffd351',
    '#ff8e43',
    '#f56b6d',
  ];

  let formatNumber = function (num: any) {
    let reg = /(?=(\B)(\d{3})+$)/g;
    return num.toString().replace(reg, ',');
  };

  // 这里对数据做了处理
  echartData = echartData?.map((v: any) => ({
    name: v.bill_type_name,
    value: v.money,
  }));

  let option: any = {
    renderer: 'svg',
    backgroundColor: bgColor,
    color: color,
    tooltip: {
      trigger: 'item',
    },
    title: [
      {
        text:
          '{name|' +
          title +
          '}\n{val|' +
          formatNumber(Number(total).toFixed(2)) +
          '}',
        top: 'center',
        left: 'center',
        textStyle: {
          rich: {
            name: {
              fontSize: getFontRpx(0.8),
              fontWeight: 'normal',
              color: '#000',
              padding: [10, 0],
            },
            val: {
              fontSize: getFontRpx(0.8),
              fontWeight: 'bolder',
              color: '#000',
            },
          },
        },
      },
      {
        text: '单位：个',
        top: 20,
        left: 20,
        textStyle: {
          fontSize: getFontRpx(0.8),
          color: '#666666',
          fontWeight: 400,
        },
        show: false,
      },
    ],
    series: [
      {
        type: 'pie',
        roseType: 'radius',
        radius: ['20%', '60%'],
        center: ['50%', '50%'],
        data: echartData,
        hoverAnimation: false,
        itemStyle: {
          normal: {
            borderColor: bgColor,
            borderWidth: 2,
          },
        },
        labelLine: {
          normal: {
            length: 10,
            length2: 5,
            lineStyle: {
              // color: '#e6e6e6'
            },
          },
        },
        label: {
          normal: {
            formatter: (params: any) => {
              return (
                '{icon|●}{name|' +
                params.name +
                '}\n{value|' +
                formatNumber(params.value) +
                '}'
              );
            },
            // padding: [0 , -100, 25, -100],
            rich: {
              icon: {
                fontSize: getFontRpx(0.8),
                color: 'inherit',
              },
              name: {
                fontSize: getFontRpx(0.8),
                padding: [0, 0, 0, 10],
                color: '#000',
              },
              value: {
                fontSize: getFontRpx(0.8),
                fontWeight: 'bolder',
                padding: [10, 0, 0, 20],
                color: 'inherit',
                // color: '#333333'
              },
            },
          },
        },
      },
    ],
  };

  return option;
}

export function genProfitTrendLine(data: Array<any>) {
  let option = {
    renderer: 'svg',
    grid: { left: 0, top: 40, bottom: 20, right: 10, containLabel: true },
    xAxis: {
      type: 'category',
      data: data.map((v: any) => v.release_year_month),
      axisLine: { lineStyle: { color: '#ccc' } },
      axisTick: { length: 3 },
      axisLabel: { color: '#999' },
    },
    yAxis: {
      type: 'value',
      axisLine: { show: true, lineStyle: { color: '#ccc' } },
      axisLabel: { color: '#999' },
      splitLine: { show: false },
    },
    tooltip: {
      trigger: 'axis',
      padding: [12, 17, 20, 23],
      textStyle: { color: '#424242' },
      renderMode: 'html',
      className: 'tooltip',
    },
    series: [
      {
        name: '收益额',
        type: 'line',
        data: data.map((v: any) => v.money),
        smooth: true, // 平滑曲线
        showSymbol: false,
        itemStyle: { color: '#126EFC' },
        lineStyle: { width: 3, color: '#126EFC' },
        areaStyle: { color: 'rgba(0, 110, 254, 0.1)' },
      },
    ],
  };
  return option;
}
