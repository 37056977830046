import { Module } from 'vuex';
import { State } from '.';

export const testModule: Module<{ id: string | number }, State> = {
  namespaced: true,
  state: { id: 1 },
  mutations: {
    test() {
      console.log('testModule test');
    },
  },
};
