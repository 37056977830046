import { usersRequest } from '../utils/http/httpRequestIndex';

export class UsersReq {
  static login(params: object) {
    return usersRequest.post('/login', params);
  }
  static register(params: object) {
    return usersRequest.post('/register', params);
  }
  static updateInfo(params: object) {
    return usersRequest.post('/update_info', params);
  }
  static updatePassword(params: object) {
    return usersRequest.post('/update_password', params);
  }
  static getUserInfo() {
    return usersRequest.get('/get_userinfo');
  }
}
