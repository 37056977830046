<template>
  <div :class="`icon-wrap${renClass(wrapClass)}`" v-bind="$listener">
    <div
      :class="`iconfont icon${renClass(iconClass)}`"
      :style="iconStyle"
    ></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, computed } from 'vue';
import { renClass } from '../../utils/renderTool';
export default defineComponent({
  name: 'icon',
  props: {
    wrapClass: {
      type: String,
    },
    iconClass: { type: String },
    color: { type: String },
  },
  setup(props: any, ctx: any): any {
    let iconStyle = computed(() => {
      let style: any = {};
      if (props.color) style.color = props.color;
      return style;
    });
    return {
      renClass,
      iconStyle,
    };
  },
});
</script>

<style lang="scss" scoped>
.icon {
  font-size: 16px;
  color: var(--text-color5);
}
</style>
