<template>
  <router-view v-if="$route.meta.keepAlive" v-slot="{ Component, route }">
    <transition :name="animation" model="out-in">
      <keep-alive :include="cacheRouteList">
        <component class="page" :is="Component" :key="getFirstLevelRoute(route)" />
      </keep-alive>
    </transition>
  </router-view>
  <router-view v-else v-slot="{ Component, route }">
    <transition :name="animation" model="out-in">
      <component class="page" :is="Component" :key="getFirstLevelRoute(route)" />
    </transition>
  </router-view>
</template>

<script lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { RouteLocation, useRoute, onBeforeRouteUpdate, useRouter } from 'vue-router';
import { routeList } from './router';
import { store, useStore } from './store';

const mainTabList = ['invest', 'user', 'home', 'statistics'];

export default {
  name: 'App',
  setup() {
    const store = useStore();
    const route: RouteLocation = useRoute();
    const router = useRouter();

    const cacheRouteList = computed(() => {
      // console.log(store.getters.cacheRouteList);
      return store.getters.cacheRouteList;
    });
    const animation = computed(() => store.state.animation);

    const methods = {
      getFirstLevelRoute(route: RouteLocation) {
        // 嵌套路由下，要保证1级组件、二级组件的key都对应得上。
        // 不然的话，会导致嵌套的组件，多次被初始化，多次mount
        // 因为外层的keepAlive会每个子组件多初始化且缓存一遍。
        if (mainTabList.includes(String(route.name))) return 'index';
        let path = route.fullPath;
        if (path.split('/')[0] === '/') return 'index';
        return route.name;
      },
    };

    onMounted(() => {
      store.commit(
        'setCacheRouteList',
        routeList.filter((v) => v?.meta?.keepAlive).map((item) => item.name)
      );
    });

    onMounted(() => {
      let win: any = window;

      // 控制uniapp的物理返回键逻辑
      win.routeBack = () => {
        if (mainTabList.includes(String(route.name))) {
          win.uni.postMessage({
            data: {
              action: true,
            },
          });
        } else {
          router.back();
        }
      };
    });

    return {
      animation,
      cacheRouteList,
      ...methods,
    };
  },
};
</script>

<style lang="scss">
@import url('styles/global.scss');
@import url('styles/global-variable.scss');
@import url('styles/task-styles.scss');

.loading-icon {
  background-color: transparent !important;
  .van-toast__icon {
    font-size: 60px !important;
  }
}
h3.clacctststst-test {
  color: #f5f5f5;
  color: #f9f9f9;
  color: #39be77;
  color: rgb(236, 190, 37);
  color: #73c097;
  color: #f5f5f5;

  color: #ffda44;
  color: #fae89f;
}

.slide-left-enter-from {
  transform: translateX(-100%);
}
.slide-left-enter-to {
  transform: translateX(0);
}
.slide-left-enter-active {
  transition: 0.5s ease-out;
}
.slide-right-enter-from {
  transform: translateX(100%);
}
.slide-right-enter-to {
  transform: translateX(0);
}
.slide-right-enter-active {
  transition: 0.5s ease-out;
}
</style>
