<template>
  <div>
    <van-nav-bar :title="pageTitle || title" left-text="返回" left-arrow @click-left="back" safe-area-inset-top fixed right>
      <template #right>
        <icon icon-class="icon-icon_home" @click="toHome" />
        <van-popover v-model:show="showPopover" theme="dark" placement="bottom-end" :actions="actions" @select="selectPopover">
          <template #reference>
            <icon icon-class="icon-leibiefenlei" />
          </template>
        </van-popover>
      </template>
    </van-nav-bar>
    <div class="main">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { reactive, toRefs } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { commonEmitter } from '../../utils/mitt/index';
import { CommonEventType } from '../../utils/mitt/eventType';
import { useStore } from '@/store';
export default {
  name: 'navbar',
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      title: String(route.meta.title || ''),
      showPopover: false,
      actions: [{ text: '记账' }, { text: '统计' }, { text: '投资' }, { text: '我的' }],
    });

    const methods = {
      back() {
        router.back();
      },
      toHome() {
        store.commit('switchTab', {
          name: 'home',
        });
      },
      selectPopover(val: any) {
        switch (val.text) {
          case '记账':
            commonEmitter.emit(CommonEventType.ShowAddBill);
            store.commit('switchTab', {
              name: 'home',
              params: {
                doAdd: 1,
              },
            });
            break;
          case '统计':
            store.commit('switchTab', {
              name: 'statistics',
            });
            break;
          case '投资':
            store.commit('switchTab', {
              name: 'invest',
            });
            break;
          case '我的':
            store.commit('switchTab', {
              name: 'user',
            });
            break;
        }
      },
    };

    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 46px;
}
:deep(.icon-icon_home) {
  margin-right: 12px;
}
:deep(.icon-leibiefenlei) {
  margin-top: 0px;
}
:deep(.van-icon) {
  color: var(--text-color1);
}
:deep(.icon) {
  color: var(--text-color1);
}
:deep(.van-nav-bar__text) {
  color: var(--text-color1);
}
:deep(.van-nav-bar__title) {
  color: var(--text-color1);
}
</style>
