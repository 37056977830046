<template>
  <van-popup
    v-model:show="show"
    position="bottom"
    :style="{ height: '80vh' }"
    round
  >
    <div class="pop">
      <div class="header van-hairline--bottom">
        请选择类型
        <van-icon name="cross" class="cross" @click="toggle" />
      </div>
      <div class="content">
        <div
          @click="choseType({ id: 'all' })"
          :class="{ all: true, active: active == 'all' }"
        >
          全部类型
        </div>
        <div class="title">支出</div>
        <div class="expense-wrap">
          <p
            @click="choseType(item)"
            :class="{ active: active == item.id }"
            v-for="item in expense"
            :key="item"
          >
            {{ item.name }}
          </p>
        </div>
        <div class="title">收入</div>
        <div class="income-wrap">
          <p
            @click="choseType(item)"
            :class="{ active: active == item.id }"
            v-for="item in income"
            :key="item"
          >
            {{ item.name }}
          </p>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script lang="ts">
import { reactive, toRefs, onMounted } from 'vue';
import { sourceTypeDict, SourceTypeDictTypes } from '../../dataDict';
import { BillReq } from '../../request/bill';

export default {
  name: 'PopType',
  props: {
    selectType: {
      selectType: Function,
    },
  },
  setup(props: any, { emit }: any) {
    const state = reactive({
      active: 'all',
      show: false,
      expense: [],
      income: [],
    });
    const toggle = () => {
      state.show = !state.show;
    };
    const choseType = (item: any) => {
      state.active = item.id;
      state.show = false;
      emit('selectType', item);
    };
    onMounted(async () => {
      const { data } = await BillReq.billTypeList();
      state.expense = data.list.filter((v: any) =>
        sourceTypeDict.equal(SourceTypeDictTypes.EXPENSE, v.source_type)
      );
      state.income = data.list.filter((v: any) =>
        sourceTypeDict.equal(SourceTypeDictTypes.INCOME, v.source_type)
      );
    });
    return {
      ...toRefs(state),
      toggle,
      choseType,
    };
  },
};
</script>

<style lang="scss" scoped>
.pop {
  position: relative;
  background-color: var(--bg-color1);
  .header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 56px;
    text-align: center;
    font-size: 14px;
    line-height: 56px;
    color: var(--text-color1);
    background-color: var(--bg-color4);
    .cross {
      position: absolute;
      left: 10px;
      top: 50%;
      font-size: 20px;
      transform: translateY(-50%);
      color: var(--text-color1);
    }
  }
  .content {
    padding: 20px;
    .all {
      text-align: center;
      display: inline-block;
      padding: 12px 0px;
      width: calc((100% - 5px * 6) / 3);
      font-size: 16px;
      color: var(--text-color1);
      background-color: var(--bg-color4);
      margin: 0px 5px;
    }
    .title {
      color: var(--text-color1);
      margin: 10px 0;
      font-size: 14px;
    }
    .expense-wrap,
    .income-wrap {
      display: flex;
      flex-wrap: wrap;
      p {
        margin: 0px 5px;
        width: calc((100% - 5px * 6) / 3);
        text-align: center;
        padding: 12px 0;
        margin-bottom: 10px;
        background-color: var(--bg-color4);
        font-size: 16px;
      }
    }
    .active {
      background-color: var(--bg-color3) !important;
      color: var(--text-color1);
    }
  }
}
</style>
